<template>
	<div class="outer-wrapper">

		<Loader :loading="isLoading"/>

		<div class="inner-wrapper" v-if="!isLoading">
			
			<div v-if="!isLoggedIn" class="not-logged-in">
				To save this flight to a trip, you must first <br>
				<button class="button" @click="loginRedirect()">Log in or Sign Up</button> 
			</div>

			<div v-else>
				<!-- There is a specific trip item to replace (trip_item_id query param). Tell the user that this is happening. -->
				<div class="replace" v-if="!newTripSelected && replaceTripItem">
					<div class="heading-section">
						<h1 class="heading4">Replace Flight</h1>
					</div>
					<div v-if="isLoggedIn" class="logged-in">
						The <span class="red">{{ this.basket.selected.flight.destination }}</span> flight in your <strong>{{ selectedTripName }}</strong> trip is being replaced by the new <span class="green">{{ this.basket.selected.flight.destination }}</span> flight
						<button class="button" @click="saveFlightToBasket(true)">Confirm</button> 
						<div><strong>Or</strong></div>
						Add as a new flight by selecting a trip below
					</div>
				</div>

				<!-- There is no specific trip item to replace (trip_item_id query param). Allow the user to select a trip -->
				<div class="heading-section" v-if="selectTripLoaded">
					<h1 class="heading6">Select a Trip</h1>
				</div>
				<div v-if="isLoggedIn" class="logged-in">
					<div>
						<SelectTrip 
							class="trip-select" 
							@continue="saveFlightToBasket"
							v-model="selectedTrip"

							@loaded="selectTripLoaded = true"
						/>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>
<style scoped>
	.heading-section {
		margin-bottom: 50px;
	}
	.replace { 
		text-align: center;
		margin-bottom: 20px;
	}
	.replace .button {
		margin: 32px auto;
		display: block;
	}
	.not-logged-in {
		text-align: center;
	}
	.not-logged-in .button {
		margin-top: 10px;
	}
	.replace .red {
		color: #FA5252;
		font-weight: bold;
	}
	.replace .green {
		color: #06D6A0;
		font-weight: bold;
	}
</style>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import SelectTrip from '@/components/trips/SelectTrip.vue'
	import { tripService } from '@/services/tripService.js';
	import Loader from '@/components/Loader'
	import track from '@/helpers/track.js'

	// Get the selected flight from state
	// On refresh (empty state) - redirect back to flights-details with the outbound/return keys
	// Use SelectTrip component to get a tripID
	// Continue then saves the flight to the selected trip
	// AddToBasket gets changed to a flight specific save function in flightSearch state
	export default {
		name: 'flightsSelectTrip',
		data() {
			return {
				outboundKey: this.$route.params.outbound_key ? this.$route.params.outbound_key : null,
				returnKey: this.$route.params.return_key ? this.$route.params.return_key : null,

				selectedTrip: this.$route.query.trip_id ? this.$route.query.trip_id : null,
				replaceTripItem: this.$route.query.trip_item_id ? this.$route.query.trip_item_id : null,

				isLoading: false,
				selectTripLoaded: false,
			}
		},
		components: {
			SelectTrip,
			Loader,
		},
		computed: {
			...mapState({
				flightsSearch: state => state.flightsSearch, 
				trip: state => state.trip, 
				basket: state => state.basket,
			}),
			...mapGetters({
				isLoggedIn: 'account/isLoggedIn',
			}),
			newTripSelected() { // Boolean whether the user has changed the selected trip
				return this.selectedTrip && (this.selectedTrip != this.$route.query.trip_id);
			},
			selectedTripName() {
				if(this.trip.tripID == this.selectedTrip) {
					return this.trip.tripName;
				}
			}
		},
		methods: {
			...mapActions({
				initTrip: "trip/initTrip",
				addToBasket: "basket/addToBasket",
				updateTripItem: "trip/updateTripItem",
				alertSuccess: "alert/success",
			}),
			async saveFlightToBasket(shouldReplace = false) {
				// If not logged in, check for existing basket data - NOT NEEDED?
				// let data = this.basket.trips['default'].items[this.outboundKey]; // Current basket item
				// if(data === undefined) {
				// 	data = {};
				// }
				let data = {};

				// Get these values from state (or this page)
				data.trip_id = this.selectedTrip;
				data.type = "flight";
				data.price = this.basket.selected.flight.fareData.price.amount;
				data.key = this.basket.selected.flight.outboundKey;
				data.outbound_key = this.basket.selected.flight.outboundKey;
				data.return_key = this.basket.selected.flight.returnKey;
				data.airline = this.basket.selected.flight.airline;
				data.origin = this.basket.selected.flight.origin;
				data.destination = this.basket.selected.flight.destination;
				data.fare = this.basket.selected.flight.fareData.key;
				data.date = this.basket.selected.flight.departure;
				this.isLoading = true;
				let addMethod = "existing trip";
				if(!this.newTripSelected && this.replaceTripItem && shouldReplace) {
					addMethod = "replace existing trip item";
				}

				// Hide the nav bar once the user has clicked save, so that they can't press back while it processes.
				this.$emit('update:topLayoutSettings', {
					hide: true,
				});
				
				if(!data.trip_id) {
					// Create new trip first
					let response = await tripService.createTrip()
					data.trip_id = response.data.data.id;
					this.alertSuccess("Trip created!");
					addMethod = "new trip";
				}
				try {
					track.event("addFlightToTrip", {
						tripID: data.trip_id,
						addMethod: addMethod, // Defines if the trip item was added to an existing trip, or a new trip was created, or a trip item was replaced in an existing trip.
						ecommerce: {
							value: this.basket.selected.flight.fareData.price.amount,
							currency: this.basket.selected.flight.fareData.price.currency,
						}
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}

				// If TripID & TripItemID - update existing item rather than adding to basket
				if(!this.newTripSelected && this.replaceTripItem && shouldReplace) { // If the trip ID has not changed and there is a trip item to replace && shouldReplace param is true
					this.updateTripItem({ tripID: this.selectedTrip, tripItemID: this.replaceTripItem, data: data }) // Remove this trip item from the trip (to replace it with new one)
						.then(response => {
							// Redirect user to the trip page (user is signed in)
							router.replace({ // Replace the current URL with the new one (so back button doesn't go back to this page). We don't want them pressing back after adding to the trip, as it would allow them to add it again, or think that they haven't added it yet.
								name: "Add Manage Trip Item Travellers",
								params: {
									trip_id: this.$route.query.trip_id,
									trip_item_id: this.$route.query.trip_item_id
								},
							});
						});
				} else {
					// Else add to basket as new item.
					this.addToBasket(data) // Use new flightSearch specific save function
						.then(response => {
							if(response.data.type != "local") { // Was sent to backend
								// Redirect user to the trip page (user is signed in)
								router.replace({ // Replace the current URL with the new one (so back button doesn't go back to this page). We don't want them pressing back after adding to the trip, as it would allow them to add it again, or think that they haven't added it yet.
									name: "Add Manage Trip Item Travellers",
									params: {
										trip_id: response.data.trip_id,
										trip_item_id: response.data.trip_item_id,
									},
								});
							} else { // Was sent to local storage
								// Send the user to the login screen (with redirect to trips page after logging in to view the trip after it syncs)
								router.push({
									name: 'Login',
									query: {
										redirect: 'my-trips'
									}
								});
							}
						}); 
				}
			},
			loginRedirect() {
				router.push({ name: 'Login', query: { redirect: window.location.pathname }})
			},
		},
		
		mounted() {
			
		},
		async created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: { name: 'Flights Details', params: { outboundKey: this.outboundKey, returnKey: this.returnKey } },
				},
				right: {
					type: 'none',
				},
			});


			if(!this.basket.selected.flight) { // If state for selected flight is empty (fare select etc)
				// Redirect to flightsDetails page so they can select this
				router.replace({
					name: "Flights Details",
					params: {
						outbound_key: this.outboundKey,
						return_key: this.returnKey,
					}
				})
			}
			
			this.isLoading = false;
		}
	}
</script>

