<template>
	<div class="select-trip">
		
		<Loader :loading="loading"/>
		
		<div v-if="!loading">
			<div class="trip" v-for="trip in basket.backendTrips" :key="trip.id">
				<div v-if="trip.id == value" class="option selected" @click="select(trip.id)">
					<span v-if="trip.trip_data && trip.trip_data.data && trip.trip_data.data.name">{{ trip.trip_data.data.name }}</span>
					<span v-else>Trip: {{ trip.id }}</span>
					<span class="select-btn">Selected</span>
					<button class="button" @click="$emit('continue')">Continue</button>
				</div>
				<div v-else class="option" @click="select(trip.id)">
					<span v-if="trip.trip_data && trip.trip_data.data && trip.trip_data.data.name">{{ trip.trip_data.data.name }}</span>
					<span v-else>Trip: {{ trip.id }}</span>
					<div @click="select(trip.id)" class="select-btn">Select</div>
				</div>
			</div>
			<div class="total">Total {{ basket.backendTrips.length }} trips</div>
			<div class="or">Or</div>
			<button class="button" @click="select(false); $emit('continue');">Create Trip</button>
		</div>
	</div>

</template>

<script>
// What happens if we edit a guest (are there trip versions, or just one version of details seeing as it's not linked to a separate user)
// -- What then, if the guest is on two separate trips, and another user edits the guests details for 1 trip (and shouldn't change on the other trip?)
import { mapState, mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader'

export default {
	props: {
		value: false,
	},
	data() {
		return {
			tripID: this.selectedTrip,
			loading: false,
		}
	},
	components: {
		Loader,
	},
	computed: {
		...mapState({
			basket: state => state.basket,
		}),
	},
	methods: {
		...mapActions({
			setSelectedTrip: "basket/setSelectedTrip",
			fetchTrips: "basket/fetchTrips",
		}),
		select(tripID) {
			// Update selected Trip ID in state
			this.value = tripID;
			this.$emit('input', this.value);
		},
	},
	created() {
		if(this.basket.backendTrips.length == 0) { // If there are no trips
			this.loading = true;
		} else {
			this.$emit('loaded');
			this.loading = false;
		}
		this.fetchTrips() // Check for trips
			.then(response => {
				if(this.basket.backendTrips.length == 0) { // If there are still no trips (we are sure this is up to date)
					// Create trip automatically
					this.select(false); // No trip selected
					this.$emit('continue');
				}
				this.$emit('loaded');
				this.loading = false;
			},
			error => {
				this.alertError(error);
				this.$emit('loaded');
				this.loading = false;
			});
	}
}
</script>

<style scoped>
	.select-trip {
		max-width: 340px;
		width: 100%;
		margin: 0 auto;

		font-size: 14px;
		text-align: center;
	}
	.select-trip .total {
		margin-top: 15px;
		margin-bottom: 15px;
		color: #50555C;
		font-size: 12px;
	}
	.select-trip .or {
		font-weight: bold;
	}
	.select-trip button {
		margin-top: 15px;
		margin-bottom: 30px;
	}
	.select-trip .option {
		height: 32px;
		border-bottom: 1px dashed #AFAFAF;
		padding: 0 5px;
		padding-top: 17px;
	}
	.select-trip .option {
		text-align: left;
	}
	.select-trip .trip:nth-child(odd) {
		background-color: #FCFCFE;
	}
	.select-trip .option.selected {
		height: auto;
		padding-bottom: 15px;
	}
	.select-trip .option.selected button {
		margin: 0 auto;
		display: block;
		margin-top: 15px;
	}
	.select-trip .select-btn {
		float: right;
		color: #118AB2;
		font-weight: bold;
		cursor: pointer;
	}
	.select-trip .option.selected .select-btn {
		color: #06D6A0;
	}
	.select-trip .option.selected span {
		font-weight: bold;
	}
</style>